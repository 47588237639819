import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { FaInstagram } from '@react-icons/all-files/fa/FaInstagram';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import { FaPinterest } from '@react-icons/all-files/fa/FaPinterest';
import { FaSpotify } from '@react-icons/all-files/fa/FaSpotify';
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter';
import { FaYoutube } from '@react-icons/all-files/fa/FaYoutube';

export const afChannels: {
  blogs: 'BLOGS';
  pages: 'PAGES';
  general: 'GENERAL';
} = {
  blogs: 'BLOGS',
  pages: 'PAGES',
  general: 'GENERAL',
};

export const afAdsetsPages = {
  '/404': '404',
  '/404/': '404',
  '/fr/404': '404',
  '/fr/404/': '404',
  '/about': 'ABOUT',
  '/about/': 'ABOUT',
  '/fr/about': 'ABOUT',
  '/fr/about/': 'ABOUT',
  '/accounts': 'ACCOUNTS',
  '/accounts/': 'ACCOUNTS',
  '/fr/accounts': 'ACCOUNTS',
  '/fr/accounts/': 'ACCOUNTS',
  '/affiliate': 'AFFILIATES',
  '/affiliate/': 'AFFILIATES',
  '/fr/affiliate': 'AFFILIATES',
  '/fr/affiliate/': 'AFFILIATES',
  '/careers': 'CAREERS',
  '/careers/': 'CAREERS',
  '/fr/careers': 'CAREERS',
  '/fr/careers/': 'CAREERS',
  '/coming-soon': 'COMINGSOON',
  '/coming-soon/': 'COMINGSOON',
  '/fr/coming-soon': 'COMINGSOON',
  '/fr/coming-soon/': 'COMINGSOON',
  '/contact': 'CONTACT',
  '/contact/': 'CONTACT',
  '/fr/contact': 'CONTACT',
  '/fr/contact/': 'CONTACT',
  '/cover': 'COVEROVERDRAFTPROTECTION',
  '/cover/': 'COVEROVERDRAFTPROTECTION',
  '/fr/cover': 'COVEROVERDRAFTPROTECTION',
  '/fr/cover/': 'COVEROVERDRAFTPROTECTION',
  '/credit-building': 'CREDIT-BUILDING',
  '/credit-building/': 'CREDIT-BUILDING',
  '/fr/credit-building': 'CREDIT-BUILDING',
  '/fr/credit-building/': 'CREDIT-BUILDING',
  '/credit-report': 'CREDITREPORT',
  '/credit-report/': 'CREDITREPORT',
  '/fr/credit-report': 'CREDITREPORT',
  '/fr/credit-report/': 'CREDITREPORT',
  '/compare': 'FINANCIAL_INSTITUTION_COMPARISON_TOOL',
  '/compare/': 'FINANCIAL_INSTITUTION_COMPARISON_TOOL',
  '/fr/compare': 'FINANCIAL_INSTITUTION_COMPARISON_TOOL',
  '/fr/compare/': 'FINANCIAL_INSTITUTION_COMPARISON_TOOL',
  '/culture': 'CULTURE',
  '/culture/': 'CULTURE',
  '/fr/culture': 'CULTURE',
  '/fr/culture/': 'CULTURE',
  '/enterprise': 'ENTERPRISE',
  '/enterprise/': 'ENTERPRISE',
  '/fr/enterprise': 'ENTERPRISE',
  '/fr/enterprise/': 'ENTERPRISE',
  '/essential': 'ESSENTIAL',
  '/essential/': 'ESSENTIAL',
  '/fr/essential': 'ESSENTIAL',
  '/fr/essential/': 'ESSENTIAL',
  '/extra': 'EXTRA',
  '/extra/': 'EXTRA',
  '/fr/extra': 'EXTRA',
  '/fr/extra/': 'EXTRA',
  '/everything': 'EVERYTHING',
  '/everything/': 'EVERYTHING',
  '/fr/everything': 'EVERYTHING',
  '/fr/everything/': 'EVERYTHING',
  '/features': 'FEATURES',
  '/features/': 'FEATURES',
  '/fr/features': 'FEATURES',
  '/fr/features/': 'FEATURES',
  '/free-credit-score': 'FREECREDIT',
  '/free-credit-score/': 'FREECREDIT',
  '/fr/free-credit-score': 'FREECREDIT',
  '/fr/free-credit-score/': 'FREECREDIT',
  '/government-benefits-eligibility': 'GOVERNMENT_BENEFITS',
  '/government-benefits-eligibility/': 'GOVERNMENT_BENEFITS',
  '/fr/government-benefits-eligibility': 'GOVERNMENT_BENEFITS',
  '/fr/government-benefits-eligibility/': 'GOVERNMENT_BENEFITS',
  '/': 'HOME',
  '/fr': 'HOME',
  '/fr/': 'HOME',
  '/learn': 'LEARN',
  '/learn/': 'LEARN',
  '/fr/learn': 'LEARN',
  '/fr/learn/': 'LEARN',
  '/legal': 'LEGAL',
  '/legal/': 'LEGAL',
  '/fr/legal': 'LEGAL',
  '/fr/legal/': 'LEGAL',
  '/line-of-credit': 'LINEOFCREDIT',
  '/line-of-credit/': 'LINEOFCREDIT',
  '/fr/line-of-credit': 'LINEOFCREDIT',
  '/fr/line-of-credit/': 'LINEOFCREDIT',
  '/metal-card': 'METALCARD',
  '/metal-card/': 'METALCARD',
  '/fr/metal-card': 'METALCARD',
  '/fr/metal-card/': 'METALCARD',
  '/new-to-canada': 'NEWTOCANADA',
  '/new-to-canada/': 'NEWTOCANADA',
  '/fr/new-to-canada': 'NEWTOCANADA',
  '/fr/new-to-canada/': 'NEWTOCANADA',
  '/powerups': 'POWERUPS',
  '/powerups/': 'POWERUPS',
  '/fr/powerups': 'POWERUPS',
  '/fr/powerups/': 'POWERUPS',
  '/referral': 'REFERRAL',
  '/referral/': 'REFERRAL',
  '/fr/referral': 'REFERRAL',
  '/fr/referral/': 'REFERRAL',
  '/renters': 'RENTERS',
  '/renters/': 'RENTERS',
  '/fr/renters': 'RENTERS',
  '/fr/renters/': 'RENTERS',
  '/reviews': 'REVIEWS',
  '/reviews/': 'REVIEWS',
  '/fr/reviews': 'REVIEWS',
  '/fr/reviews/': 'REVIEWS',
  '/save': 'CASH-BACK',
  '/save/': 'CASH-BACK',
  '/fr/save': 'CASH-BACK',
  '/fr/save/': 'CASH-BACK',
  '/status': 'STATUS',
  '/status/': 'STATUS',
  '/fr/status': 'STATUS',
  '/fr/status/': 'STATUS',
  '/travel': 'TRAVEL',
  '/travel/': 'TRAVEL',
  '/fr/travel': 'TRAVEL',
  '/fr/travel/': 'TRAVEL',
  '/instant-approval-virtual-card': 'VIRTUAL_CARD',
  '/instant-approval-virtual-card/': 'VIRTUAL_CARD',
  '/fr/instant-approval-virtual-card': 'VIRTUAL_CARD',
  '/fr/instant-approval-virtual-card/': 'VIRTUAL_CARD',
};

// : TFooterLinkObject: Readd after french for travel included

export const linksFooter = () => ({
  social: [
    {
      icon: FaInstagram,
      platform: 'Instagram',
      to: 'https://www.instagram.com/getkoho/?hl=en',
    },
    {
      icon: FaTwitter,
      platform: 'Twitter',
      to: 'https://twitter.com/GetKoho',
    },
    {
      icon: FaFacebookF,
      platform: 'Facebook',
      to: 'https://www.facebook.com/KOHO/',
    },
    {
      icon: FaYoutube,
      platform: 'Youtube',
      to: 'https://www.youtube.com/channel/UC-HnC-dcg5_KYW6pQ7H4JQA',
    },
    {
      icon: FaLinkedin,
      platform: 'LinkedIn',
      to: 'https://www.linkedin.com/company/koho',
    },
    {
      icon: FaPinterest,
      platform: 'Pinterest',
      to: 'https://www.pinterest.ca/getkoho/',
    },
    {
      icon: FaSpotify,
      platform: 'Spotify',
      to: 'https://open.spotify.com/user/3n9gcir6n134y1xdhrn08nylj?si=a2-6anaGT3KZjo2gG0ctww',
    },
  ],
});
